<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
			<!-- 搜索框 -->
			<el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
					<el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable
						style="width: 230px" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
					<el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<!-- <el-button type="primary" icon="el-icon-plus" size="mini" @click.stop="handleAdd">新增</el-button> -->
				<!-- <right-toolbar :show-search.sync="showSearch" @queryTable="query" /> -->
			</el-row>
			<!--表格-->
			<el-table v-loading="loading" size="small" :data="list" element-loading-text="Loading" highlight-current-row
				style="width: 100%">
				<el-table-column label="ID" width="80" align="center">
					<template slot-scope="scope">
						{{ scope.row.id }}
					</template>
				</el-table-column>
				<el-table-column label="单位" min-width="110" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="缴费金额" min-width="110" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.money || '' }}</span>
					</template>
				</el-table-column>
				<el-table-column label="缴费记录" min-width="110" align="center" prop="desc"></el-table-column>
				<el-table-column label="缴费时间" min-width="110" align="center" prop="date"></el-table-column>
				<el-table-column label="发票地址" min-width="110" align="center" prop="url"></el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.status === 1" size="mini" type="danger">待审核</el-tag>
						<el-tag v-if="scope.row.status === 2" size="mini" type="warning">申请发票</el-tag>
						<el-tag v-if="scope.row.status === 3" size="mini" type="success">已开票</el-tag>
						<el-tag v-if="scope.row.status === 4" size="mini" type="danger">不通过</el-tag>
						<el-tag v-if="scope.row.status === 5" size="mini" type="success">已审核</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="170">
					<template slot-scope="scope">
						<i class="el-icon-time" />
						<span>{{ scope.row.ctime }}</span>
					</template>
				</el-table-column>
			</el-table>
			<pagination :total="total" auto-scroll :page.sync="queryForm.page" :limit.sync="queryForm.limit"
				@pagination="query" />
		</el-card>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	export default {
		inject: ['app'],
		mixins: [common],
		components: {
			GlobalTips,
			Breadcrumb
		},
		data() {
			return {
				preUrl: 'user',
				autoRequest: false,
				queryForm: {
					page: 1,
					limit: 10,
					user_id:'',
					grade:1,
					keyword: '',
				},
			}
		},
		mounted() {
           if(this.$route.query.id){
           	 this.queryForm.user_id = this.$route.query.id
			 this.getList()
           }
		},
		methods: {
			getList() {
			  this.loading = true
			  this.axios.get(`/manage/${this.preUrl}/user_jfjl`, {
			    params: this.queryForm
			  }).then(res => {
			    this.total = parseInt(res.data.total)
			    this.list = res.data.list
			    this.loading = false
			  }).catch(err => {
			    this.loading = false
			  })
			},
		}
	}
</script>

<style>
</style>